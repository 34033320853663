@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;900&family=Noto+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&family=Noto+Sans:wght@400;900&family=Noto+Serif&display=swap');
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.home-banner {
  background-color: #DDF6FA;
  padding: 30px 0;
}
.home-banner.height {height:91vh;}

.home-banner .corner-img {
 position: absolute;
 top: 0;
 left: 0;
 opacity: 0.2;
 width: 450px;

}
.home-banner h1 {
  padding-top: 60px;
  font-size: 58px;
  color: #1F5764;
  font-weight: 500;
  font-family: 'EB Garamond', serif;
}

.home-banner p {
  font-size: 19px;
  color: #1F5764;
  font-weight: 500;
}
.home-banner h4 {
  font-size: 1.2rem;
}
.home-banner p.card-title {
  font-size: 18px;
  color: #1F5764;
  font-weight: 600;
}
.emoji {width: 30px;margin-top: -5px;}
.icon-img {width: 150px;}
.input-btn {background-color: rgb(218, 211, 211);
  border: 1px solid rgb(218, 211, 211);
  text-transform: uppercase;
  font-size: 17px;
  color: #1F5764;
  font-weight: 600;}
  .generate-btn {border-radius: unset;
    color: #fff;
    background-color: #1F5764;
    border-color: #1F5764;
    font-size: 19px;
    box-shadow: 0 10px 15px -8px rgba(0,0,0,.7);
  }
  .generate-btn:hover {background-color: #1F5764;border-color: #1F5764;box-shadow: unset;transition: 0.5s;}
  .form-color {background-color: #DDF6FA;}
  .home-banner .loader{height:100px;}
  
  .home-banner .unlock-block p {font-weight: 400;}
  .home-banner .card {box-shadow: -1px 1px 14px 0px rgba(0,0,0,0.3);}

.footer {background-color: #DDF6FA;}
.footer p {color: #1F5764;font-weight: 600;font-size: 14px;}
.footer .social ul {padding-left: unset;}
.footer .social ul li{display:inline-block;}
.footer .social ul li a i{font-size: 14px; color: #000; width:30px;height:30px;line-height:30px;text-align:center;border:1px solid rgb(88, 94, 99);border-radius:20px;margin-right: 6px;transition:0.4s;}
.footer .social ul li a i:hover{background-color: #6c757d;border-color: #6c757d;}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 992px) { 
  .home-banner {
   padding: unset;
  }
  .home-banner.home-banner.height{height:unset;}
}
@media (max-width: 768px) { 
  .home-banner {
    padding: 30px 0;
  }
   .home-banner h1 {
    padding-top: unset;
    font-size: 40px;
   }
}
@media (max-width: 576px) { 
  .home-banner .corner-img {
   width: 300px;
   }
   .home-banner h1 {
    font-size: 35px;
   }
   .home-banner p {
    font-size: 18px;
   }
   .input-btn {font-size: 16px;}
   .generate-btn {font-size: 18px;}
}
@media (max-width: 300px) { 
  .home-banner .corner-img {
   width: 250px;
   }
   .home-banner h1 {
    font-size: 30px;
   }
   .home-banner p {
    font-size: 16px;
   }
}